import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { Maybe } from 'yup/lib/types';
import { Button } from '../../components/LinkButton';
import { Money } from '../../domain/Money';
import ChevronIcon from './components/ChevronIcon';
import PricingCard, { Action } from './components/PricingCard';
import FeaturesTable from './components/Table';
import useFormFactors from '../../helpers/useFormFactors';
import { HeightBasedCollapsible } from './components/HeightBasedCollapsible';
import { columns } from './components/tabledata.example';
type IBenefit = { text: string; textColor?: string | null; color: string | null };

export interface IPricing {
  tag: Maybe<{
    title: string;
    color: string;
  }>;
  note: Maybe<string>;
  banner: Maybe<{ text: string }>;
  benefits: IBenefit[];
  hiddenBenefits: Maybe<IBenefit[]>;
  subtitle: string;
  price: Money;
  pricingType: string;
  frequency: Maybe<string>;
  label: string;
}

export interface B2CSubscriptionPricingProps {
  pricings: IPricing[];
  pricingAction: Action<IPricing>;
  id: string;
  title: string;
  titleAlignment?: 'left' | 'center';
  cardClassName?: string;
}

const Layout = styled.section`
  z-index: 3;
  padding: 82px 0 0px 0;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  .pricing-card {
    flex: 1;
    min-width: 300px;
  }
`;

const PricingContainer = styled.div`
  column-gap: 48px;
  row-gap: 24px;
  align-items: center;
  flex-wrap: wrap;
  display: flex;
  margin-bottom: 75px;
`;

const SeeAllFeaturesCtaAndTableContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 1 1 100%;
`;

const SeeAllFeaturesCtaButton = styled(Button)`
  background-color: transparent;
  border: 1px solid ${(props) => props.theme.colors.gray40};
  color: ${(props) => props.theme.colors.gray40};
  display: flex;
  column-gap: 10px;
  align-items: center;
  margin-bottom: 75px;
  p {
    color: inherit;
  }
`;

const Heading = styled.h1`
  margin-bottom: 40px;
  flex: 1 1 100%;
  font-weight: 500;
  line-height: 70px;
  width: 352px;
  font-size: 48px;
  text-align: left;
  margin-left: 20px;
  & > div {
    max-width: 652px;
  }
  @media (min-width: 768px) {
    font-size: 64px;
    width: 600px;
  }
`;

const YellowText = styled.span`
  color: ${(props) => props.theme.colors.orange}};
`;

const GroupCelebrationHeading = () => (
  <Heading>
    <div>
      Get The <YellowText>Group Celebration</YellowText> Platform
    </div>
  </Heading>
);

const SlackPricingSection: FC<{
  pricings: IPricing[];
  pricingAction: Action<IPricing>;
  id: string;
  showTitle?: boolean;
  featureColumns?: any;
}> = ({ pricings, pricingAction, id, showTitle = true, featureColumns = columns, ...props }) => {
  const [collapsed, setCollapsed] = useState(true);
  const { tablet, laptop, laptopAll, mobile } = useFormFactors();
  const showCollapsible = tablet || laptop || laptopAll;
  return (
    <Layout id={id} {...props}>
      <PricingContainer>
        {showTitle && <GroupCelebrationHeading />}
        {pricings.map((pricing, i) => {
          const ctaVariant = pricing.tag ? 'primary' : 'secondary';
          return (
            <PricingCard
              key={i}
              className={'pricing-card'}
              banner={pricing.banner}
              note={pricing.note}
              per={pricing.frequency}
              label={pricing.label}
              subtitle={pricing.subtitle}
              tag={
                !mobile
                  ? pricing.tag || {
                      title: '',
                      color: '',
                    }
                  : pricing.tag
              }
              benefits={pricing.benefits}
              hiddenBenefits={pricing.hiddenBenefits}
              formattedPrice={
                pricing.price.equalsTo(Money.fromCents(0))
                  ? 'Free'
                  : // check if it ends with 00 and remove it
                  pricing.price.toFormat().endsWith('00')
                  ? pricing.price.toFormat().slice(0, -3)
                  : pricing.price.toFormat()
              }
              ctaVariant={ctaVariant}
              hiddenBenefitsEnabled={mobile}
              action={{ name: pricingAction.name, onClick: () => pricingAction.onClick(pricing) }}
              styleBenefit={
                !mobile
                  ? {
                      margin: 'auto',
                      width: '272px',
                    }
                  : undefined
              }
            />
          );
        })}
      </PricingContainer>

      {showCollapsible && (
        <SeeAllFeaturesCtaAndTableContainer>
          <SeeAllFeaturesCtaButton onClick={() => setCollapsed((prev) => !prev)}>
            <p>See all features and compare plans</p>
            <ChevronIcon direction={collapsed ? 'down' : 'up'} />
          </SeeAllFeaturesCtaButton>
          <HeightBasedCollapsible isOpen={!collapsed} containerProps={{ style: { width: '100%' } }}>
            <FeaturesTable columns={featureColumns} />
          </HeightBasedCollapsible>
        </SeeAllFeaturesCtaAndTableContainer>
      )}
    </Layout>
  );
};

export default SlackPricingSection;
