import React, { FC } from 'react';
import styled from 'styled-components';
import { FAQS_LINK } from '../constants/links';
import { FAQS } from './Faq.data';
import { FaqElement } from './pricing/components/FaqElement';

const Layout = styled.section`
  z-index: 3;
  padding: 82px 20px;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const FaqContainer = styled.div`
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  margin-bottom: 24px;
`;

const HeadlineComponent = styled.h1`
  font-family: 'brandon-grotesque';
  font-weight: 450;
  line-height: 70px;
  letter-spacing: 0em;
  text-align: center;
  font-size: 64px;
  margin-bottom: 40px;
  @media (max-width: 768px) {
    font-size: 36px;
    margin-bottom: 24px;
  }
  @media (max-width: 480px) {
    font-size: 24px;
    margin-bottom: 16px;
  }
`;

const SmallGreyText = styled.p`
  font-size: 14px;
  font-weight: 390;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(40, 40, 41, 0.6);
`;

const FAQLink = styled.a`
  text-decoration: none;
  color: grey;
  text-decoration: underline;
  cursor: pointer;
`;

const FAQText = () => (
  <SmallGreyText>
    Still have questions? Visit our FAQ{' '}
    <FAQLink href={FAQS_LINK} target='_blank'>
      here
    </FAQLink>
    .
  </SmallGreyText>
);

const FaqSection: FC<{
  faqs?: typeof FAQS;
}> = ({ faqs = FAQS }) => {
  return (
    <Layout>
      <HeadlineComponent>Frequently Asked Questions</HeadlineComponent>
      <FaqContainer>
        {faqs.map((faq, index) => {
          return <FaqElement key={index} faq={faq} />;
        })}
      </FaqContainer>
      <FAQText />
    </Layout>
  );
};

export default FaqSection;
