export const FAQS = [
  {
    question: 'What is the right plan for me?',
    answer: `If you plan to send more than three cards a year, the annual plan is the best option. Not only do you save on postage, stamps, and all the costs that come with physical cards, but you receive competitive pricing compared to other digital solutions. Plus, you’re able to split the cost of a gift—something that illume is pioneering, which will delight and create savings for everyone.`,
  },
  {
    question: 'Can I try before I upgrade to the Premium plan? ',
    answer: `Totally. Our free tier allows you to experience illume with no credit card required.`,
  },
  {
    question: 'Is there a pricing discount for educational institutions?',
    answer: `We do offer discounts to education institutions. Please reach out to email@tryillume.com to learn more.`,
  },
];
