import React from 'react';
import { motion } from 'framer-motion';
import styled, { ThemeProvider } from 'styled-components';

import MetaTags from '../../components/MetaTags';

import { theme } from '../../styles/theme';

import Background from '../../components/Background';
import Header from '../../components/Header2';
import { EVENT_TRACKS } from '../../constants/eventTracks';
import { getMainAppFullPath } from '../../constants/getUrl';
import { SegmentTracker } from '../../domain/Tracker';
import FaqSection from '../../sections/Faq';
import Join from '../../sections/Join';
import FooterNew from '../../sections/common/FooterWithoutFormFactorHook';
import Companies from '../../sections/marketing/Companies';
import LoveIllume from '../../sections/marketing/LoveIllume';
import data from '../../sections/marketing/data';
import PricingSection from '../../sections/pricing/PricingSection';
import { SLACK_PRICING_DATA } from '../../sections/pricing/data/B2B_PRICING_DATA';
import SlackPricingSection from '../../sections/pricing/SlackPricingSection';
import { slackColumns } from '../../sections/pricing/components/tabledata.example';

const StyledFooter = styled(FooterNew)`
  padding-left: 0 !important;
`;

const Layout = styled(motion.div)`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 0 24px;
  position: relative;
  width: 100%;
  .pricing-card {
    min-height: 550px;
  }

  @media ${(props) => props.theme.breakpoints.tabletP} {
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    -webkit-backface-visibility: hidden;
  }
`;

const StyledPriceSection = styled(SlackPricingSection)`
  margin-top: 80px;
  padding: 0 24px;
`;

const B2C_HREF_ID = 'individuals';

const StyledCompanies = styled(Companies)`
  margin-bottom: 80px;
`;

export const SLACK_FAQS = [
  {
    question: 'What happens when I add illume to Slack?',
    answer:
      'Only you will be able to see that you’ve added illume. First, you click on “Add to Slack” button. You’ll then be taken to Slack’s download page. After accepting the permissions and completing a short onboarding, you should be signed up!',
  },
  {
    question: 'Will adding illume trigger an announcement or notifications to my company?',
    answer: 'No, other team members will not get announcement that illume is downloaded.',
  },
  {
    question: 'Is illume free to use?',
    answer:
      'Each account can send up to two cards for free. For additional cards beyond that—our unlimited plan—it is $199/year.',
  },
];

const SlackPricingPage = () => {
  return (
    <ThemeProvider theme={theme}>
      <MetaTags
        title='The Celebration Platform | illume'
        description={
          'From birthdays to just because, illume is reimagining how the world celebrates.'
        }
      />
      <Layout>
        <Background showTopBlob={false} showMiddleBlob={false} />
        <Header />

        <StyledPriceSection
          title=''
          id={B2C_HREF_ID}
          // yes use the B2b in the B2C data
          pricings={SLACK_PRICING_DATA}
          cardClassName='pricing-card'
          pricingAction={{
            onClick: (pricing) => {
              // alert('Oops, this feature is still under development and will be available soon!');
              const path = getMainAppFullPath('/slack-plans' + window.location.search);
              window.location.href = path;
            },
            name: 'Get Started',
          }}
          featureColumns={slackColumns}
        />

        <StyledCompanies text='illume is trusted and used by organizations around the World' />
        <FaqSection faqs={SLACK_FAQS} />
        <LoveIllume testimonials={data.testimonials} />
        <Join
          ctaText='Create the group gift'
          ctaSubtext='Bring people together no matter where they are.'
          showSlackBtn
        />
        <StyledFooter />
      </Layout>
    </ThemeProvider>
  );
};

export default SlackPricingPage;
